<template>
  <!-- === header_nav_sp/ === -->
  <div class="header_nav_sp" :class="{ header_nav_sp_on: showSpMenu }">
    <template v-if="develop">
      <!-- SP版ヘッダーのハンバーガーメニューから開くメニュー -->
    </template>
    <div class="menu--header_sp">
      <!-- ===== mypage_nav_menu/ ===== -->
      <div class="">
      <!-- <div class="mypage_nav_menu mypage_nav_menu_lv1"> -->
        <template v-if="isLoggedIn">
        <ul id="global_nav_menu_list" class="mypage_nav_menu_list">
          <li>
            <a
              href="/mypage"
              class="mypage"
              >マイページ</a
            >
          </li>
          <li>
            <a
              href="/mypage/setting/profile"
              class="setting_profile"
              >プロフィール</a
            >
          </li>
          <li >
            <a
              v-on:mouseover="accountSettingHover = true" v-on:mouseleave="accountSettingHover = false"
              ref="accountSettingMenu"
              href="/mypage/setting/basic"
              class="setting"
              >アカウント設定
              </a>
          </li>
          <li>
            <a
              href="/mypage/works"
              class="manage"
              >案件管理</a
            >
          </li>
          <li>
            <a
              href="/mypage/reward"
              class="money"
              >報酬管理</a
            >
          </li>
          <li>
            <a
              href="/job"
              class="search"
              >案件検索</a
            >
          </li>
          <li>
            <a
              href="/interest"
              class="interest"
              >気になるリスト</a
            >
          </li>
          <li>
            <a
              href="/magazine"
              class="magazine"
              >スキイキマガジン</a
            >
          </li>
          <li>
            <a
              href="/topic"
              class="topic"
              >お知らせ</a
            >
          </li>
          <li>
            <a href="/logout" class="logout">
              <span>ログアウト</span>
            </a>    
          </li>
        </ul>

        <div v-if="progress" class="wrapper--graph">
          <p class="text--progress">プロフィール入力状況</p>
          <div class="graph--progress" :style="progressGraphStyle">
            {{ progress }}<span class="percent">％</span>
          </div>
        </div>
        <div v-else class="wrapper--graph">
          <p class="text--progress">プロフィール入力状況</p>
          <div class="graph--progress zero_percent">
            0<span class="percent">％</span>
          </div>
        </div>
        <!-- <div v-else class="wrapper--graph" style="height: 215px;">
        </div> -->
      </template>
      <template v-else>
        <ul id="global_nav_menu_list" class="mypage_nav_menu_list">
          <li>
            <a
              href="/job"
              class="search"
              >案件検索</a
            >
          </li>
          <li>
            <a
              href="/interest"
              class="interest"
              >気になるリスト</a
            >
          </li>
          <li>
            <a
              href="/magazine"
              class="magazine"
              >スキイキマガジン</a
            >
          </li>
          <li>
            <a
              href="/topic"
              class="topic"
              >お知らせ</a
            >
          </li>
          <li>
            <a href="/login" class="login">
              <span>ログイン</span>
            </a>    
          </li>
        </ul>
      </template>
        <ul id="global_under_nav_menu_list" class="mypage_under_nav_menu_list">
          <li>
            <a href="/guide">使い方ガイド</a>
          </li>
          <li>
            <a href="/contact">お問い合わせ</a>
          </li>
          <!-- <li>
            <a href="/terms">会員規約</a>
          </li>
          <li>
            <a href="/privacy1">個人情報の取り扱いについて</a>
          </li> -->
        </ul>
      </div>
      <!-- ===== /mypage_nav_menu ===== -->
      <div class="header_nav_sp_close">
        <a
          class="header_nav_sp_close_btn"
          @click="closeSpMenuTrigger"
        >
          <span>閉じる</span>
        </a>
      </div>
    </div>
    <JobCategoryDialog
      v-model="visibleJobCategories"
      :values="checkedJobCategories"
      :dialogType="'search'"
      @commit="commitJobCategoriesCheck"
    ></JobCategoryDialog>
  </div>
  <!-- === /header_nav_sp===  -->
</template>

<script>
const JobCategoryDialog = () =>
import("@/components/job-search/JobCategoryDialog");
export default {
  name: "HeaderMenuSP",
  props: {
    showSpMenu: Boolean,
  },
  components: { JobCategoryDialog },
  data() {
    return {
      visibleJobCategories: false,
      checkedJobCategories: [],
    }
  },
  computed: {
    newJobCount() {
      return this.$store.state.jobs.newJobInfo.newJobCount;
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    progress() {
      return this.$store.state.profileStatus.progress;
    },
    progressGraphStyle() {
      const percent = this.$store.state.profileStatus.progress;
      return "background-image: radial-gradient(#f2f2f2 47%, transparent 48%), conic-gradient(#15C4B9 0% " + percent + "%, #d9d9d9 " + percent +"% 100%);";
    },
  },
  methods: {
    commitJobCategoriesCheck(checkedList) {
      this.checkedJobCategories = checkedList; //  このcheckedListはコンポーネントから受け取った値
      this.searchByJobCategory();
    },
    searchByJobCategory() {
      this.$store.commit("jobsearch/resetConditions");
      this.$store.commit(
        "jobsearch/setCheckedJobCategories",
        this.checkedJobCategories
      );
      this.$store.commit("jobsearch/setCurrentPage", 1);

      const path = this.$store.getters["jobsearch/path"];
      this.$router.push(path);
    },
    closeSpMenuTrigger() {
      this.$emit('closeSpMenuTrigger', false);
    }
  },
  watch: {
    $route(to) {
      this.showSpMenu = false;
      to.name == "JobIdEntry2"
        ? (this.simpleHeader = true)
        : (this.simpleHeader = false);
      if (to.name == "Home") {
        this.isTopPage = true;
      } else {
        this.isTopPage = false;
      }
    },
    showSpMenu() {
      if (this.showSpMenu) {
        this.$store.commit("profile/setShowSpModalBack", true);

        // メニューが開いている間はページ全体のスクロールを無効にする
        document.body.style.overflow = 'hidden';
        document.body.style.maxHeight = '100vh';

      } else {
        this.$store.commit("profile/setShowSpModalBack", false);

        // 元のスクロール状態に戻す
        document.body.style.overflow = '';
        document.body.style.maxHeight = '';
      }
    },
  },
  mounted() {

    // 新着案件情報（件数のみ）の取得
    this.$store.dispatch("jobs/getNewJobInfo", true);

    if( this.$store.state.auth.isLoggedIn && this.$store.state.profileStatus.profile == null ) {
      this.$store.dispatch("profileStatus/getProfileStatus").catch((e) => {
        console.log(e);
      });
    }

    // 他のページに遷移した時、spメニューが開いていたら閉じる
    this.$router.beforeEach((to, from, next) => {
      this.showSpMenu = false;
      next();
    });
  },
};
</script>
