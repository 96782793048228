
export default {
  namespaced: true,
  state: {
    pageTitle: '',
  },
  mutations: {
    setPageTitle(state, value)  {
      state.pageTitle = value;
    },
    resetPageTitle(state) {
      state.pageTitle = '';
    },
  },
  actions: {

  },
  modules: {
  }
}