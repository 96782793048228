<template>
  <div class="header--normal">

    <!-- ===== header/ ===== -->
    <header>
      <div
        class="header_container"
        :style="
          isMessageChat
            ? { maxWidth: '100%', paddingRight: '20px', paddingLeft: '20px' }
            : {}
        "
      >
        <!-- === header_detail/ === -->
        <div class="header_detail">
          <div class="header_siteid">
            <a href="/" class="siteid_logo"
              >スキルが活きる、スキルで生きる スキイキ produced by マイナビ</a
            >
          </div>
          <!-- PC版リンク -->
          <div class="header_link_detail" v-if="$mq == 'pc'">
              <a href="/job" class="btn--jobsearch btn--header_link_item">
                <span>案件を探す</span>
              </a>
              <a href="/interest" class="btn--favorite btn--header_link_item">
                <span>気になるリスト</span>
              </a>
          </div>
          <!-- SP版リンク -->
          <div class="header_link_detail" v-else>
              <a href="/job" class="btn--jobsearch btn--header_link_item">
                <img class="img--message_icon" src="/assets/images/common/icn_search_bl_line.png" alt />
                <span>案件検索</span>
              </a>
              <a href="/interest" class="btn--favorite btn--header_link_item">
                <img class="img--message_icon" src="/assets/images/common/icn_star_line.png" alt />
                <span>気になる</span>
              </a>
              <template v-if="isLoggedIn">
                <a href="/mypage/message-chat" class="btn--favorite btn--header_link_item link--header_icon" :class="{ mark: unreadFlg }">
                  <img class="img--message_icon" src="/assets/images/parts/icon/header/chat.png" alt />
                  <span>メッセージ</span>
                </a>
                <a href="/mypage/scout" class="btn--favorite btn--header_link_item">
                  <img class="img--scout_icon" src="/assets/images/parts/icon/header/scout.png" alt />
                  <span>スカウト</span>
                  <span v-if="unreadScoutCount > 0" class="unread_count">{{ unreadScoutCount }}</span>
                </a>
              </template>
              <template v-else>
                <a href="/signup" class="btn--sign_up btn--header_link_item">
                  <img class="img--message_icon" src="/assets/images/common/icn_add_account.png" alt />
                  <span>会員登録</span>
                </a>
              </template>
              <a
                class="header_spnav_btn header_spnav_btn_sp gray"
                :class="{ navon: showSpMenu }"
                @click="showSpMenu = !showSpMenu"
              >
                <span class="on" v-if="!showSpMenu"></span>
                <span class="off" v-if="showSpMenu"></span>
              </a>
          </div>

          <nav v-if="$mq == 'pc'" class="header_nav_member">
            <ul class="header_nav_member_detail header_nav_icon_list" :class="{ ul__login: isLoggedIn }">
              <!-- ログイン時 -->
              <template v-if="isLoggedIn">
                <li class="">
                    <a class="link--header_icon" href="/mypage/message-chat" :class="{ mark: unreadFlg }">
                      <div class="area--link">
                        <img class="img--message_icon" src="/assets/images/parts/icon/header/chat.png" alt />
                        <span class="text--message_icon">メッセージ</span>
                      </div>
                      <span class="parts__anime"></span>
                    </a>
                </li>
                <li class="">
                    <a class="link--header_icon" href="/mypage/scout">
                      <div class="area--link">
                        <img class="img--message_icon" src="/assets/images/parts/icon/header/scout.png" alt />
                        <span class="text--message_icon">スカウト</span>
                        <span v-if="unreadScoutCount > 0" class="unread_count">{{ unreadScoutCount }}</span>
                      </div>
                      <span class="parts__anime"></span>
                    </a>
                </li>
                <li class="header_nav_user  delsp">
                  <span class="userimage">
                    <img :src="profileImage" alt />
                  </span>
                </li>
              </template>
              <!-- 非ログイン時 -->
              <template v-else>
                <li class="list--btn">
                  <a href="/login" class="btn--login">ログイン</a>
                </li>
                <li class="list--btn">
                  <a href="/signup" class="btn--sign_up">会員登録</a>
                </li>
              </template>
              <li class="list_item--nav_icon">
                <a
                  class="no-pcmenu-close header_spnav_btn gray"
                  :class="{ navon: showPcMenu }"
                  @click="showPcMenu = !showPcMenu"
                >
                  <span class="no-pcmenu-close on" v-if="!showPcMenu"></span>
                  <span class="no-pcmenu-close off" v-if="showPcMenu"></span>
                </a>
              </li>
            </ul>
          </nav>
          <!-- <HeaderMenuPC v-if="$mq == 'pc'" :showPcMenu="showPcMenu" @closeSpMenuTrigger="showPcMenu = false"></HeaderMenuPC> -->
        </div>
        <!-- === /header_detail === -->
      </div>
      <div id="wrapper--header_menu" class="wrapper--header_menu">
        <HeaderMenuPC v-if="$mq == 'pc'" :showPcMenu="showPcMenu" @closeSpMenuTrigger="showPcMenu = false"></HeaderMenuPC>
      </div>
      <!-- /header_container -->
      <HeaderMenuSP v-if="$mq == 'sp'" :showSpMenu="showSpMenu" @closeSpMenuTrigger="closeSpMenuTrigger"></HeaderMenuSP>
    </header>
    <!-- ===== /header ===== -->
  </div>
</template>

<script>
import HeaderMenuSP from "@/components/partial/HeaderMenuSP";
import HeaderMenuPC from "@/components/partial/HeaderMenuPC";

const listener = {
  methods: {
    listen: function(target, eventType, callback) {
      if (!this._eventRemovers) {
        this._eventRemovers = [];
      }
      target.addEventListener(eventType, callback);
      this._eventRemovers.push({
        remove: function() {
          target.removeEventListener(eventType, callback);
        },
      });
    },
  },
  destroyed: function() {
    if (this._eventRemovers) {
      this._eventRemovers.forEach(function(eventRemover) {
        eventRemover.remove();
      });
    }
  },
};

export default {
  name: "HeaderNormal",
  props: {},
  mixins: [listener],
  data() {
    return {
      visibleJobCategories: false,
      checkedJobCategories: [],
      showMenu: false,
      showPcMenu: false,
      showSpMenu: false,
    };
  },
  components: {
    // JobCategoryDialog,
    HeaderMenuSP, HeaderMenuPC
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    isMessageChat() {
      if (
        this.$mq == "pc" &&
        (this.$route.name == "MyPage_MessageChats" ||
          this.$route.name == "MyPage_MessageChats_EntryId")
      ) {
        return true;
      } else {
        return false;
      }
    },
    pageTitle() {
      return this.$store.state.mypage.pageTitle;
    },
    profile() {
      if (this.isLoggedIn) {
        return this.$store.state.profile;
      } else {
        return {};
      }
    },
    profileImage() {
      if (this.$store.state.profile.profileImage) {
        return this.$store.state.profile.profileImage;
      }
      return "/assets/images/common/prof_noimage.png";
    },
    newJobCount() {
      return this.$store.state.jobs.newJobInfo.newJobCount;
    },
    unreadFlg() {
      if (this.isLoggedIn) {
        if (this.$store.state.profile.messageUnreadFlg) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    unreadScoutCount() {
      if (this.isLoggedIn) {
        if(this.$store.state.scouts.unreadCount && this.$store.state.scouts.unreadCount > 0){
          return this.$store.state.scouts.unreadCount;
        }else{
          return 0;
        }
      }else{
        return 0;
      }
    },
  },

  methods: {
    searchWithProfileInfo() {
      this.$store.commit("jobsearch/resetSearchResult");
      this.$store
        .dispatch("jobsearch/setConditionsFromProfile")
        .then(() => {
          var path = this.$store.getters["jobsearch/path"];
          if (this.$route.path == path) {
            this.showMenu = false;
            this.showPcMenu = false;
            this.showSpMenu = false;
          } else {
            this.$router.replace(path);
          }
        })
        .catch((e) => {
          console.log(e);
          this.showMenu = false;
          this.showPcMenu = false;
          this.showSpMenu = false;
        });
    },
    commitJobCategoriesCheck(checkedList) {
      this.checkedJobCategories = checkedList; //  このcheckedListはコンポーネントから受け取った値
      this.searchByJobCategory();
    },
    searchByJobCategory() {
      this.$store.commit("jobsearch/resetConditions");
      this.$store.commit(
        "jobsearch/setCheckedJobCategories",
        this.checkedJobCategories
      );
      this.$store.commit("jobsearch/setCurrentPage", 1);
      // this.showConditionSP = false;

      const path = this.$store.getters["jobsearch/path"];
      this.$router.push(path);
    },
    menuLink(to) {
      this.showMenu = false;
      if (this.$route.path == to) return;
      this.$router.push(to);
    },
    closeMenu() {
      this.showMenu = false;
    },
    closeSpMenuTrigger(bool) {
      this.showSpMenu = bool;
    },
  },
  watch: {
    showSpMenu() {
      if (this.showSpMenu) {
        this.$store.commit("profile/setShowSpModalBack", true);
      } else {
        this.$store.commit("profile/setShowSpModalBack", false);
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      if (!this.$store.state.profile.loaded) {
        this.$store
          .dispatch("profile/getProfile")
          .then(() => {
            this.$store
              .dispatch("profileStatus/getProfileStatus")
              .then(() => {});
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.$store
        .dispatch("scouts/getScouts", 1)
        .then(() => {});
    }

    console.log(document.title);

    // 新着案件情報（件数のみ）の取得
    this.$store.dispatch("jobs/getNewJobInfo", true);

    // 他のページに遷移した時、メニューが開いていたら閉じる
    this.$router.beforeEach((to, from, next) => {
      this.showPcMenu = false;
      this.showSpMenu = false;
      next();
    });
  },
};
</script>
