<template>
  <div class="header--normal">

    <!-- ===== header/ ===== -->
    <header>
      <div class="header_container">
        <!-- === header_detail/ === -->
        <div class="header_detail">
          <div class="header_siteid">
            <a href="/" class="siteid_logo"
              >スキルが活きる、スキルで生きる スキイキ produced by マイナビ</a
            >
          </div>
        </div>
        <!-- === /header_detail === -->
      </div>
      <!-- /header_container -->
    </header>
    <!-- ===== /header ===== -->
  </div>
</template>

<script>
export default {
  name: "SimpleHeader",
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
