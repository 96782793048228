import Vue from 'vue'
import Vuex from 'vuex'

import constants from '@/store/modules/constants'
import signup from '@/store/modules/signup'
import profile from '@/store/modules/profile'
import profileStatus from '@/store/modules/profile-status'
import jobsearch from '@/store/modules/jobsearch'
import skills from '@/store/modules/skills'
import jobs from '@/store/modules/jobs'
import works from '@/store/modules/works'
import messagechats from '@/store/modules/messagechats'
import auth from '@/store/modules/auth'
import banks from '@/store/modules/banks'
import bankAccount from '@/store/modules/bank-account'
import muteWords from '@/store/modules/mute-words'
import scouts from '@/store/modules/scouts'
import verify from '@/store/modules/verify'
import rewards from '@/store/modules/rewards'
import nda from '@/store/modules/nda'
import invoiceNumber from '@/store/modules/invoice-number'
import unreadScouts from '@/store/modules/unread-scouts'
import temporaryJob from '@/store/modules/temporary-job'
import reminder from '@/store/modules/reminder'
import offer from '@/store/modules/offer'
import offers from '@/store/modules/offers'
import report from '@/store/modules/report'
import entry from '@/store/modules/entry'
import contact from '@/store/modules/contact'
import company from '@/store/modules/company'
import corporateAbuse from '@/store/modules/company-abuse'
import follows from '@/store/modules/follows'
import recommendJobs from '@/store/modules/jobs-recommend'
import mypageJobs from '@/store/modules/jobs-mypage'
import currentProfile from '@/store/modules/current-profile'
import history from '@/store/modules/history'
import interests from '@/store/modules/interests'
import entryProfile from '@/store/modules/entry-profile'
import withdrawal from '@/store/modules/withdrawal'
import temporaryCompany from '@/store/modules/temporary-company'
import social from '@/store/modules/social'
import topics from '@/store/modules/topics'
import guides from '@/store/modules/guides'
import publish from '@/store/modules/publish'
import magazines from '@/store/modules/magazines'
import banners from '@/store/modules/banners'
import pickups from '@/store/modules/pickups'
import breadCrumbs from '@/store/modules/breadCrumbs'

import emailDomains from '@/store/modules/utils/emailDomains'
import mypage from '@/store/modules/mypage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    constants: constants,
    signup: signup,
    profile: profile,
    jobsearch: jobsearch,
    skills: skills,
    jobs: jobs,
    works: works,
    messagechats: messagechats,
    auth: auth,
    banks: banks,
    bankAccount: bankAccount,
    muteWords: muteWords,
    scouts: scouts,
    verify: verify,
    rewards: rewards,
    nda: nda,
    invoiceNumber: invoiceNumber,
    profileStatus: profileStatus,
    unreadScouts: unreadScouts,
    temporaryJob: temporaryJob,
    temporaryCompany: temporaryCompany,
    reminder: reminder,
    offer: offer,
    offers: offers,
    report: report,
    entry: entry,
    contact: contact,
    company: company,
    corporateAbuse: corporateAbuse,
    follows: follows,
    recommendJobs: recommendJobs,
    mypageJobs: mypageJobs,
    currentProfile: currentProfile,
    history: history,
    interests: interests,
    entryProfile: entryProfile,
    withdrawal: withdrawal,
    social: social,
    topics: topics,
    guides: guides,
    publish: publish,
    magazines: magazines,
    banners: banners,
    pickups: pickups,
    breadCrumbs: breadCrumbs,
    emailDomains: emailDomains,
    mypage: mypage,
  }
})

export default store